import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getCelebInfo, getCelebVideos } from "../../services/firebase.service";
import CelebInfo from "../../components/CelebInfo/CelebInfo";
import Video from "../../components/Video/Video";
import Header from "../../components/Header/Header";
import Message from "../../components/Message/Message";
import Ad from "../../components/Ad/Ad";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import useOnScreen from "../../utils/OnScreen.hook";
import useOnWidth from "../../utils/OnWidth.hook";
import { Helmet } from "react-helmet";

function CelebPage() {
    let { celeb_name } = useParams();
    const [celebInfo, setCelebInfo] = useState(null);
    const [celebVideos, setCelebVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [videoPagination, setVideoPagination] = useState(null);
    const loader = useRef();
    const loaderOnScreen = useOnScreen(loader);
    const responsive = useOnWidth();
    const [noMoreVideos, setNoMoreVideos] = useState(false);

    useEffect(() => {
        const getMoreInfo = async () => {
            setLoading(true);

            let { videosInfo, lastVideoPagination } = await getCelebVideos(
                decodeURIComponent(celeb_name),
                5,
                videoPagination
            );
            if (videosInfo.length === 0) setNoMoreVideos(true);

            setVideoPagination(lastVideoPagination);
            setCelebVideos((currenCelebVideos) => [
                ...currenCelebVideos,
                ...videosInfo,
            ]);
            setLoading(false);
        };

        if (loaderOnScreen && !loading && !noMoreVideos) getMoreInfo();
    }, [loaderOnScreen]);

    useEffect(() => {
        const getInfo = async () => {
            setCelebVideos([]);
            setCelebInfo(null);
            setLoading(true);

            let celebInfoData = await getCelebInfo(
                decodeURIComponent(celeb_name)
            );
            if (!celebInfoData.data()) {
                document.title = "Celeb Not Found • IGLiveStream";
                setNoMoreVideos(true);
                setLoading(false);
                return;
            }
            document.title = `${decodeURIComponent(celeb_name)} (${
                celebInfoData.data().celeb_insta_name
            }) • Live Streams`;

            let { videosInfo, lastVideoPagination } = await getCelebVideos(
                decodeURIComponent(celeb_name),
                5
            );
            if (videosInfo.length === 0) setNoMoreVideos(true);

            setVideoPagination(lastVideoPagination);
            setCelebInfo(celebInfoData);
            setCelebVideos(videosInfo);
            setLoading(false);
        };

        getInfo();
    }, [decodeURIComponent(celeb_name)]);

    return (
        <>
            {!responsive.isDesktop && (
                <Header
                    message={decodeURIComponent(celeb_name)}
                    shouldShowBackButton={true}
                ></Header>
            )}
            {celebInfo !== null && (
                <>
                    <Helmet>
                        <meta
                            name="description"
                            content={`${decodeURIComponent(celeb_name)} (${
                                celebInfo.data().celeb_insta_name
                            }) archived Instagram live streams.`}
                        ></meta>
                    </Helmet>
                    <CelebInfo celeb={celebInfo}></CelebInfo>
                </>
            )}
            {!loading && celebInfo === null && (
                <Message message="Celebrity Doesn't Exist"></Message>
            )}
            {celebVideos.map((videoInfo, index) => (
                <div key={videoInfo.id} className="video-plus-ad">
                    <Video
                        video={videoInfo}
                        celeb={celebInfo}
                        shouldShowFollowButton={false}
                    />
                    <Ad index={index}></Ad>
                </div>
            ))}
            {!loading && celebInfo !== null && celebVideos.length === 0 && (
                <Message message="No Live Streams Yet"></Message>
            )}
            {loading && <LoadingAnimation></LoadingAnimation>}
            <div ref={loader} style={{ height: "1px", width: "1px" }}></div>
        </>
    );
}

export default CelebPage;
