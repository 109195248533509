import React from "react";
import "./Header.scss";
import logo from "../../assets/IGLiveStreamLogo.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

function Header({ shouldShowLogo, message, shouldShowBackButton }) {
    let history = useHistory();

    return (
        <div className="header">
            <div className="header-main">
                {shouldShowBackButton && (
                    <div onClick={history.goBack} className="back-button"></div>
                )}
                {shouldShowLogo && (
                    <h1 className="header-logo-h1">
                        <Link to="/">
                            <img
                                alt="IGLiveStream Logo"
                                className="logo"
                                src={logo}
                            />
                        </Link>
                    </h1>
                )}
                {!shouldShowLogo && message && (
                    <h1
                        className="message"
                        style={{
                            left: shouldShowBackButton && "-12px",
                        }}
                    >
                        {message}
                    </h1>
                )}
            </div>
        </div>
    );
}

export default Header;
