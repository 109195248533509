export function getFollowList() {
    return JSON.parse(localStorage.followList || "[]");
}

export function followCeleb(celebName) {
    const followList = getFollowList();
    followList.push(celebName);
    localStorage.followList = JSON.stringify(followList);
}

export function unfollowCeleb(celebUnfollowName) {
    localStorage.followList = JSON.stringify(
        getFollowList().filter(function (celebName) {
            return celebName !== celebUnfollowName;
        })
    );
}

export function isFollowingCeleb(celebName) {
    return getFollowList().includes(celebName);
}
