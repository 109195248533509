import React, { useState, useRef } from "react";
import "../../components/Header/Header.scss";
import "../../components/Search/Search.scss";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import SearchResult from "../../components/SearchResult/SearchResult";
import { getCelebsListByNameAndInstaName } from "../../services/firebase.service";
import Message from "../../components/Message/Message";
import useOnWidth from "../../utils/OnWidth.hook";
import { Helmet } from "react-helmet";

function SearchPage() {
    const [searchResults, setSearchResults] = useState([]);
    const searchInput = useRef("");
    const [loading, setLoading] = useState(false);
    const [emptyResults, setEmptyResults] = useState(false);
    const responsive = useOnWidth();

    const handleChange = async () => {
        setEmptyResults(false);
        setLoading(true);
        document.title = "Search Celebrities • IGLiveStream";

        if (searchInput.current.value !== "") {
            let querySearchResults = await getCelebsListByNameAndInstaName(
                searchInput.current.value.toLowerCase(),
                20
            );

            if (querySearchResults.length === 0) {
                setSearchResults([]);
                setEmptyResults(true);
            } else setSearchResults(querySearchResults);
        } else {
            setSearchResults([]);
        }

        setLoading(false);
    };

    return (
        <>
            <Helmet>
                <meta name="description" content="Search celebrities."></meta>
            </Helmet>
            {!responsive.isDesktop && (
                <div className="header">
                    <div className="header-main">
                        <input
                            id="search"
                            type="search"
                            placeholder="Search"
                            ref={searchInput}
                            list="celebs"
                            autoComplete="off"
                            onChange={handleChange}
                            className="search-input"
                        />
                    </div>
                </div>
            )}
            <div
                className="search-results"
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "8px",
                }}
            >
                {!loading && searchResults.length !== 0 ? (
                    searchResults.map((celebInfo) => (
                        <SearchResult
                            celeb={celebInfo}
                            key={celebInfo.id}
                        ></SearchResult>
                    ))
                ) : !loading && !emptyResults ? (
                    <Message message="Search Celebrities"></Message>
                ) : !loading && emptyResults ? (
                    <Message message="No Results"></Message>
                ) : (
                    <LoadingAnimation></LoadingAnimation>
                )}
            </div>
        </>
    );
}

export default SearchPage;
