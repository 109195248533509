import React, { useState, useEffect, useRef } from "react";
import Video from "../../components/Video/Video";
import {
    getExploreCelebsVideos,
    getCelebsInfoFromVideos,
} from "../../services/firebase.service";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import useOnScreen from "../../utils/OnScreen.hook";
import Ad from "../../components/Ad/Ad";
import useOnWidth from "../../utils/OnWidth.hook";
import Header from "../../components/Header/Header";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function ExplorePage() {
    const [exploreVideosInfo, setExploreVideosInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const loader = useRef();
    const loaderOnScreen = useOnScreen(loader);
    const responsive = useOnWidth();
    const location = useLocation();

    useEffect(() => {
        const getMoreInfo = async () => {
            setLoading(true);

            let videosInfo = await getExploreCelebsVideos(5);
            let celebsInfo = await getCelebsInfoFromVideos(videosInfo);
            let fullVideosInfo = videosInfo.map((videoInfo) => {
                return {
                    videoInfo: videoInfo,
                    celebInfo: celebsInfo[videoInfo.data().celeb_name],
                };
            });

            setExploreVideosInfo((currentVideosInfo) => [
                ...currentVideosInfo,
                ...fullVideosInfo,
            ]);
            setLoading(false);
        };

        if (loaderOnScreen && !loading) getMoreInfo();
    }, [loaderOnScreen]);

    useEffect(() => {
        const getVideosInfo = async () => {
            setLoading(true);

            let videosInfo = await getExploreCelebsVideos(5);
            let celebsInfo = await getCelebsInfoFromVideos(videosInfo);
            let fullVideosInfo = videosInfo.map((videoInfo) => {
                return {
                    videoInfo: videoInfo,
                    celebInfo: celebsInfo[videoInfo.data().celeb_name],
                };
            });

            setExploreVideosInfo(fullVideosInfo);
            setLoading(false);
        };

        getVideosInfo();
    }, []);

    return (
        <>
            {location.pathname === "/explore" && (
                <Helmet>
                    <title>Explore Celebrities • IGLiveStream</title>
                    <meta
                        name="description"
                        content="Explore celebrities archived Instagram live streams."
                    ></meta>
                </Helmet>
            )}
            {!responsive.isDesktop && location.pathname === "/explore" && (
                <Header message="Explore"></Header>
            )}
            {exploreVideosInfo.map(({ videoInfo, celebInfo }, index) => (
                <div key={videoInfo.id} className="video-plus-ad">
                    <Video
                        video={videoInfo}
                        celeb={celebInfo}
                        shouldShowFollowButton={true}
                    />
                    <Ad index={index}></Ad>
                </div>
            ))}
            {loading && <LoadingAnimation></LoadingAnimation>}
            <div ref={loader} style={{ height: "1px", width: "1px" }}></div>
        </>
    );
}

export default ExplorePage;
