import React from "react";
import "./Video.scss";
import FollowButton from "../FollowButton/FollowButton";
import { Link } from "react-router-dom";
import { getDate } from "../../utils/date.util";

function Video({ celeb, video, shouldShowFollowButton }) {
    return (
        <article className="video">
            <header className="video-head">
                <Link to={`/celeb/${celeb.id}`}>
                    <img
                        src={celeb.data().celeb_profile_pic_url}
                        alt={celeb.id}
                        className="celeb-avatar"
                    />
                </Link>
                <div className="celeb-names">
                    <Link to={`/celeb/${celeb.id}`}>
                        <div className="celeb-name">{celeb.id}</div>
                    </Link>
                    <Link to={`/celeb/${celeb.id}`}>
                        <div className="celeb-insta-name">
                            {celeb.data().celeb_insta_name}
                        </div>
                    </Link>
                </div>
                {shouldShowFollowButton && (
                    <div className="follow-button-container-video">
                        <FollowButton celebName={celeb.id}></FollowButton>
                    </div>
                )}
            </header>
            <div className="video-frame">
                <iframe
                    src={`https://www.youtube.com/embed/${video.id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={video.id}
                ></iframe>
            </div>
            <div className="video-bottom">
                <div className="time">
                    <Link to={`/video/${video.id}`}>
                        <time dateTime={video.data().publish_time}>
                            {getDate(video.data().publish_time)}
                        </time>
                    </Link>
                </div>
            </div>
        </article>
    );
}

export default Video;
