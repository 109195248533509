import React, { useState } from "react";
import {
    isFollowingCeleb,
    unfollowCeleb,
    followCeleb,
} from "../../services/localStorage.service";
import "./FollowButton.scss";

function FollowButton({ celebName, isCelebInfo }) {
    const [isFollowing, setIsFollowing] = useState(isFollowingCeleb(celebName));

    const reverseFollowing = () => {
        if (isFollowing) {
            unfollowCeleb(celebName);
            setIsFollowing(false);
        } else {
            followCeleb(celebName);
            setIsFollowing(true);
        }
    };

    return (
        <>
            <button
                onClick={reverseFollowing}
                className={`follow-button ${
                    isFollowing ? "following" : "unfollowing"
                } ${isCelebInfo && "celeb-info-follow-button"}`}
            >
                {isFollowing ? "Following" : "Follow"}
            </button>
        </>
    );
}

export default FollowButton;
