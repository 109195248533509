import React, { useState, useEffect, useRef } from "react";
import Video from "../../components/Video/Video";
import {
    getCelebsVideos,
    getCelebsInfoFromVideos,
} from "../../services/firebase.service";
import { getFollowList } from "../../services/localStorage.service";
import Message from "../../components/Message/Message";
import ExplorePage from "../ExplorePage/ExplorePage";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import useOnScreen from "../../utils/OnScreen.hook";
import Ad from "../../components/Ad/Ad";
import useOnWidth from "../../utils/OnWidth.hook";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";

function HomePage() {
    const [homeVideosInfo, setHomeVideosInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [videoPagination, setVideoPagination] = useState(null);
    const loader = useRef();
    const loaderOnScreen = useOnScreen(loader);
    const [noMoreVideos, setNoMoreVideos] = useState(false);
    const responsive = useOnWidth();

    useEffect(() => {
        const getMoreInfo = async () => {
            setLoading(true);

            if (getFollowList().length === 0) {
                setLoading(false);
                return;
            }

            let { videosInfo, lastVideoPagination } = await getCelebsVideos(
                getFollowList(),
                5,
                videoPagination
            );
            if (videosInfo.length === 0) setNoMoreVideos(true);

            let celebsInfo = await getCelebsInfoFromVideos(videosInfo);
            let fullVideosInfo = videosInfo.map((videoInfo) => {
                return {
                    videoInfo: videoInfo,
                    celebInfo: celebsInfo[videoInfo.data().celeb_name],
                };
            });

            setVideoPagination(lastVideoPagination);
            setHomeVideosInfo((currentVideosInfo) => [
                ...currentVideosInfo,
                ...fullVideosInfo,
            ]);
            setLoading(false);
        };

        if (loaderOnScreen && !loading && !noMoreVideos) getMoreInfo();
    }, [loaderOnScreen]);

    useEffect(() => {
        const getVideosInfo = async () => {
            setLoading(true);
            document.title =
                "IGLiveStream • Celebrities IG Live Streams Archive";

            if (getFollowList().length === 0) {
                setLoading(false);
                setNoMoreVideos(true);
                return;
            }

            let { videosInfo, lastVideoPagination } = await getCelebsVideos(
                getFollowList(),
                5
            );
            if (videosInfo.length === 0) setNoMoreVideos(true);

            let celebsInfo = await getCelebsInfoFromVideos(videosInfo);
            let fullVideosInfo = videosInfo.map((videoInfo) => {
                return {
                    videoInfo: videoInfo,
                    celebInfo: celebsInfo[videoInfo.data().celeb_name],
                };
            });

            setVideoPagination(lastVideoPagination);
            setHomeVideosInfo(fullVideosInfo);
            setLoading(false);
        };

        getVideosInfo();
    }, []);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Celebrities Instagram live streams archive."
                ></meta>
                <link
                    rel="shortcut icon"
                    href={process.env.PUBLIC_URL + "/IGLiveStream192.png"}
                />
            </Helmet>
            {!responsive.isDesktop && <Header shouldShowLogo={true}></Header>}
            {homeVideosInfo.map(({ videoInfo, celebInfo }, index) => (
                <div key={videoInfo.id} className="video-plus-ad">
                    <Video
                        video={videoInfo}
                        celeb={celebInfo}
                        shouldShowFollowButton={false}
                    />
                    <Ad index={index}></Ad>
                </div>
            ))}
            {noMoreVideos && (
                <>
                    <Message message="Explore New Celebrities"></Message>
                    <ExplorePage></ExplorePage>
                </>
            )}
            {loading && <LoadingAnimation></LoadingAnimation>}
            <div ref={loader} style={{ height: "1px", width: "1px" }}></div>
        </>
    );
}

export default HomePage;
