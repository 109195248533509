import React from "react";
import "./SearchResult.scss";
import { Link } from "react-router-dom";

function SearchResult({ celeb }) {
    return (
        <div className="search-result">
            <Link to={`/celeb/${celeb.id}`}>
                <img
                    src={celeb.data().celeb_profile_pic_url}
                    alt={celeb.id}
                    className="celeb-avatar"
                />
                <div className="celeb-names">
                    <div className="celeb-name">{celeb.id}</div>
                    <div className="celeb-insta-name">
                        {celeb.data().celeb_insta_name}
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default SearchResult;
