import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyCEaLgipT71pYZs8uV3wFSuudsSX6Uq6Nw",
    authDomain: "iglivestream.firebaseapp.com",
    databaseURL: "https://iglivestream.firebaseio.com",
    projectId: "iglivestream",
    storageBucket: "iglivestream.appspot.com",
    messagingSenderId: "520361749183",
    appId: "1:520361749183:web:0b01747e087ef4760c740a",
    measurementId: "G-LD38WSF87Y",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const perf = getPerformance(app);
