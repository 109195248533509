import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Tabs from "./components/Tabs/Tabs";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import CelebPage from "./pages/CelebPage/CelebPage";
import ExplorePage from "./pages/ExplorePage/ExplorePage";
import VideoPage from "./pages/VideoPage/VideoPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import useOnWidth from "./utils/OnWidth.hook";
import Message from "./components/Message/Message";
import Header from "./components/Header/Header";

function App() {
    const responsive = useOnWidth();

    return (
        <Router>
            {responsive.isDesktop && <Navbar></Navbar>}
            <div className="main">
                {!navigator.onLine ? (
                    <>
                        {!responsive.isDesktop && (
                            <Header shouldShowLogo={true}></Header>
                        )}
                        <Message message="No Internet Connection"></Message>
                    </>
                ) : (
                    <Switch>
                        <Route
                            path="/video/:video_id"
                            children={<VideoPage />}
                        ></Route>
                        <Route
                            path="/explore"
                            children={<ExplorePage />}
                        ></Route>
                        <Route
                            path="/celeb/:celeb_name"
                            children={<CelebPage />}
                        ></Route>
                        <Route path="/search" children={<SearchPage />}></Route>
                        <Route path="/" children={<HomePage />}></Route>
                    </Switch>
                )}
            </div>
            {!responsive.isDesktop && <Tabs></Tabs>}
        </Router>
    );
}

export default App;
