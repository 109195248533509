import {
    collection,
    query,
    orderBy,
    getDocs,
    startAt,
    limit,
    endAt,
    where,
    getDoc,
    doc,
} from "firebase/firestore";
import { db } from "../utils/firebase.config";

export async function getCelebsListByName(searchText, numResults) {
    const queryName = query(
        collection(db, "celebs"),
        orderBy("celeb_name_lowercase"),
        startAt(searchText),
        endAt(searchText + "~"),
        limit(numResults)
    );
    const querySnapshotName = await getDocs(queryName);
    return querySnapshotName.docs;
}

export async function getCelebsListByInstaName(searchText, numResults) {
    const queryInstaName = query(
        collection(db, "celebs"),
        orderBy("celeb_insta_name"),
        startAt(searchText),
        endAt(searchText + "~"),
        limit(numResults)
    );
    const querySnapshotInstaName = await getDocs(queryInstaName);
    return querySnapshotInstaName.docs;
}

export async function getCelebsListByNameAndInstaName(searchText, numResults) {
    return [
        ...(await getCelebsListByName(searchText, numResults)),
        ...(await getCelebsListByInstaName(searchText, numResults)),
    ]
        .filter(
            (celebInfo, index, self) =>
                index ===
                self.findIndex((element) => element.id === celebInfo.id)
        )
        .slice(0, numResults);
}

export async function getVideoInfo(videoId) {
    return await getDoc(doc(db, "videos", videoId));
}

export async function getCelebInfo(celebName) {
    return await getDoc(doc(db, "celebs", celebName));
}

export async function getCelebsInfoFromVideos(videosList) {
    let celebsInfo = {};
    await Promise.all(
        videosList.map(async (videoInfo) => {
            celebsInfo[videoInfo.data().celeb_name] = await getCelebInfo(
                videoInfo.data().celeb_name
            );
        })
    );
    return celebsInfo;
}

export async function getIGLiveStreamNumVideos() {
    const countersRef = await getDoc(doc(db, "info", "counters"));
    return countersRef.data().num_videos;
}

export async function getCelebsVideos(
    celebsList,
    numVideos,
    videoPagination = 0
) {
    var maxFirestoreInArray = 10;
    var queryVideosResults = [];
    for (let i = 0, j = celebsList.length; i < j; i += maxFirestoreInArray) {
        let celebsListChunk = celebsList.slice(i, i + maxFirestoreInArray);

        let queryVideos = query(
            collection(db, "videos"),
            where("celeb_name", "in", celebsListChunk),
            orderBy("publish_time", "desc"),
            limit(numVideos + videoPagination)
        );

        queryVideosResults = queryVideosResults.concat(
            await (
                await getDocs(queryVideos)
            ).docs
        );
    }

    return {
        videosInfo: queryVideosResults
            .sort((firstVideo, secondVideo) => {
                return (
                    Date.parse(secondVideo.data().publish_time) -
                    Date.parse(firstVideo.data().publish_time)
                );
            })
            .slice(videoPagination, videoPagination + numVideos),
        lastVideoPagination: videoPagination + numVideos,
    };
}

export async function getExploreCelebsVideos(numVideos) {
    const igLiveStreamNumVideo = await getIGLiveStreamNumVideos();
    const randomNumbers = Array.from({ length: numVideos * 2 }, () =>
        Math.floor(Math.random() * igLiveStreamNumVideo)
    );

    const queryVideos = query(
        collection(db, "videos"),
        where("index", "in", randomNumbers),
        limit(numVideos)
    );

    const querySnapshotVideos = await getDocs(queryVideos);
    return querySnapshotVideos.docs;
}

export async function getCelebVideos(
    celebName,
    numVideos,
    lastVideoPagination = null
) {
    return await getCelebsVideos([celebName], numVideos, lastVideoPagination);
}
