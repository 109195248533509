import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import { Provider } from "r`eact-redux";
// import { createStore } from "redux";
// import rootReducer from "./reducers/rootReducer";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// const store = createStore(rootReducer);

ReactDOM.render(
    // <Provider store={store}>
    //     <App />
    // </Provider>,
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();
