import React, { useState, useRef } from "react";
import "./Search.scss";
import { getCelebsListByName } from "../../services/firebase.service";
import { useHistory } from "react-router-dom";

export default function Search() {
    const [searchResults, setSearchResults] = useState([]);
    const searchInput = useRef("");
    const history = useHistory();

    const handleInput = () => {
        if (searchResults.includes(searchInput.current.value)) {
            history.push(`/celeb/${searchInput.current.value}`);
            searchInput.current.blur();
        }
    };

    const handleChange = async () => {
        if (searchInput.current.value !== "") {
            let querySearchResults = await getCelebsListByName(
                searchInput.current.value.toLowerCase(),
                6
            );
            if (querySearchResults.length === 0) {
                setSearchResults([]);
            } else
                setSearchResults(querySearchResults.map((celeb) => celeb.id));
        } else {
            setSearchResults([]);
        }
    };

    return (
        <>
            <input
                id="search"
                type="search"
                placeholder="Search"
                ref={searchInput}
                list="celebs"
                autoComplete="off"
                onChange={handleChange}
                onInput={handleInput}
                className="search-input"
            />
            <datalist id="celebs">
                {searchResults.map((celebName) => (
                    <option key={celebName} value={celebName}></option>
                ))}
            </datalist>
        </>
    );
}
