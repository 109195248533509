import React from "react";
import "./CelebInfo.scss";
import FollowButton from "../FollowButton/FollowButton";
import useOnWidth from "../../utils/OnWidth.hook";

function CelebInfo({ celeb }) {
    const responsive = useOnWidth();

    return (
        <div className="celeb-info-container">
            <div className="celeb-info">
                <img
                    src={celeb.data().celeb_profile_pic_url}
                    alt={celeb.id}
                    className="celeb-avatar"
                />
                <div className="celeb-data">
                    <h1 className="celeb-name">{celeb.id}</h1>
                    <div className="follow-button-container-celeb-info">
                        <FollowButton
                            isCelebInfo={true}
                            celebName={celeb.id}
                        ></FollowButton>
                    </div>
                    {responsive.isDesktop && (
                        <>
                            <div className="celeb-insta-name">
                                {celeb.data().celeb_insta_name}
                            </div>
                            <div className="celeb-num-videos">
                                <span className="celeb-num-videos-number">
                                    {celeb.data().num_videos}
                                </span>{" "}
                                live streams
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!responsive.isDesktop && (
                <div className="celeb-other-info-mobile">
                    <div className="celeb-insta-name">
                        {celeb.data().celeb_insta_name}
                    </div>
                    <div className="celeb-num-videos">
                        <span className="celeb-num-videos-number">
                            {celeb.data().num_videos}
                        </span>{" "}
                        live streams
                    </div>
                </div>
            )}
        </div>
    );
}

export default CelebInfo;
