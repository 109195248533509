import React from "react";
import "./Tabs.scss";
import { Link, useLocation } from "react-router-dom";
import HomeOutlineIcon from "../../assets/HomeOutlineIcon.svg";
import HomeFilledIcon from "../../assets/HomeFilledIcon.svg";
import CompassOutlineIcon from "../../assets/CompassOutlineIcon.svg";
import CompassFilledIcon from "../../assets/CompassFilledIcon.svg";
import SearchOutlineIcon from "../../assets/SearchOutlineIcon.svg";
import SearchFilledIcon from "../../assets/SearchFilledIcon.svg";

function Tabs() {
    const location = useLocation();

    return (
        <nav className="tabs">
            <div className="tabs-main">
                <Link to="/">
                    <div className="tab">
                        <img
                            alt="Home"
                            src={
                                location.pathname === "/" ||
                                location.pathname === ""
                                    ? HomeFilledIcon
                                    : HomeOutlineIcon
                            }
                        />
                    </div>
                </Link>
                <Link to="/search">
                    <div className="tab">
                        <img
                            alt="Search"
                            src={
                                location.pathname === "/search"
                                    ? SearchFilledIcon
                                    : SearchOutlineIcon
                            }
                        />
                    </div>
                </Link>
                <Link to="/explore">
                    <div className="tab">
                        <img
                            alt="Explore"
                            src={
                                location.pathname === "/explore"
                                    ? CompassFilledIcon
                                    : CompassOutlineIcon
                            }
                        />
                    </div>
                </Link>
            </div>
        </nav>
    );
}

export default Tabs;
