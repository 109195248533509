import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Video from "../../components/Video/Video";
import { getCelebInfo, getVideoInfo } from "../../services/firebase.service";
import Message from "../../components/Message/Message";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import useOnWidth from "../../utils/OnWidth.hook";
import Header from "../../components/Header/Header";
import { getDate } from "../../utils/date.util";
import { Helmet } from "react-helmet";

function VideoPage() {
    let { video_id } = useParams();
    const [videoInfo, setVideoInfo] = useState(null);
    const [celebInfo, setCelebInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const responsive = useOnWidth();

    useEffect(() => {
        const getInfo = async () => {
            setLoading(true);

            let videoInfoData = await getVideoInfo(video_id);
            if (!videoInfoData.data()) {
                document.title = "Video Doesn't Exist • IGLiveStream";
                setLoading(false);
                return;
            }
            let celebInfoData = await getCelebInfo(
                videoInfoData.data().celeb_name
            );
            document.title = `${videoInfoData.data().celeb_name} (${
                celebInfoData.data().celeb_insta_name
            }) • ${getDate(videoInfoData.data().publish_time)} • Live Stream`;

            setVideoInfo(videoInfoData);
            setCelebInfo(celebInfoData);
            setLoading(false);
        };

        getInfo();
    }, [video_id]);

    return (
        <>
            {!responsive.isDesktop && (
                <Header
                    message="Live Stream"
                    shouldShowBackButton={true}
                ></Header>
            )}
            {!loading && celebInfo !== null && videoInfo !== null ? (
                <>
                    <Video
                        video={videoInfo}
                        celeb={celebInfo}
                        shouldShowFollowButton={true}
                    ></Video>
                    <Helmet>
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "VideoObject",
                                name: `${videoInfo.data().celeb_name} (${
                                    celebInfo.data().celeb_insta_name
                                }) | Instagram Live Stream | ${getDate(
                                    videoInfo.data().publish_time
                                )}`,
                                description: `${videoInfo.data().celeb_name} (${
                                    celebInfo.data().celeb_insta_name
                                }) archived Instagram live stream from ${getDate(
                                    videoInfo.data().publish_time
                                )}.`,
                                thumbnailUrl: videoInfo.data().thumbnails,
                                uploadDate: `${videoInfo.data().publish_time}`,
                                duration: `${videoInfo.data().duration}`,
                                contentUrl: `https://www.youtube.com/watch?v=${videoInfo.id}`,
                                embedUrl: `https://www.youtube.com/embed/${videoInfo.id}`,
                            })}
                        </script>
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "BreadcrumbList",
                                itemListElement: [
                                    {
                                        "@type": "ListItem",
                                        position: 1,
                                        name: videoInfo.data().celeb_name,
                                        item: `https://iglivestream.com/celeb/${
                                            videoInfo.data().celeb_name
                                        }`,
                                    },
                                    {
                                        "@type": "ListItem",
                                        position: 2,
                                        name: `${
                                            videoInfo.data().celeb_name
                                        } | Instagram Live Stream | ${getDate(
                                            videoInfo.data().publish_time
                                        )}`,
                                    },
                                ],
                            })}
                        </script>
                        <meta
                            name="description"
                            content={`${celebInfo.id} (${
                                celebInfo.data().celeb_insta_name
                            }) archived Instagram live stream from ${getDate(
                                videoInfo.data().publish_time
                            )}.`}
                        ></meta>
                    </Helmet>
                </>
            ) : !loading ? (
                <Message message="Video Doesn't Exist"></Message>
            ) : (
                <LoadingAnimation></LoadingAnimation>
            )}
        </>
    );
}

export default VideoPage;
