import React from "react";
import "./LoadingAnimation.scss";

function LoadingAnimation() {
    return (
        <>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
}

export default LoadingAnimation;
