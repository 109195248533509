import React from "react";
import "./Navbar.scss";
import logo from "../../assets/IGLiveStreamLogo.png";
import Search from "../Search/Search";
import HomeOutlineIcon from "../../assets/HomeOutlineIcon.svg";
import HomeFilledIcon from "../../assets/HomeFilledIcon.svg";
import CompassOutlineIcon from "../../assets/CompassOutlineIcon.svg";
import CompassFilledIcon from "../../assets/CompassFilledIcon.svg";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
    const location = useLocation();

    return (
        <nav className="navbar">
            <div className="main-nav">
                <div className="box">
                    <h1 className="navbar-logo-h1">
                        <Link to="/">
                            <img
                                alt="IGLiveStream Logo"
                                className="logo"
                                src={logo}
                            />
                        </Link>
                    </h1>
                </div>
                <div className="box">
                    <Search></Search>
                </div>
                <div className="box">
                    <div className="navbar-tabs">
                        <Link to="/">
                            <img
                                alt="Home"
                                src={
                                    location.pathname === "/" ||
                                    location.pathname === ""
                                        ? HomeFilledIcon
                                        : HomeOutlineIcon
                                }
                            />
                        </Link>
                        <Link to="/explore">
                            <img
                                alt="Explore"
                                src={
                                    location.pathname === "/explore"
                                        ? CompassFilledIcon
                                        : CompassOutlineIcon
                                }
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
